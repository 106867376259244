import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Hidden } from '@material-ui/core';

export const Copyright = () => {
    return (
        <div className="flexBox f-between" style={{height:"80px", maxHeight:"100px", borderTop:"1px solid #525151", backgroundColor:"#191f23", paddingRight:"50px", paddingLeft:"50px"}}>
            <div style={{height:"100%", backgroundColor:"#FFD102", width:"max-content"}}>
            <Link to="/">
                <img className="logo-footer lazyload" height="100" width="100" style={{height:"100%", width:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/logo/footer-logo.svg"} alt="yates equipement" />
            </Link>
            </div>
            <Hidden only={["xs","sm"]}>
            <div>
            <p style={{color:"#fff", position:"relative", top:"50%", transform:"translateY(-50%)"}}>Copyright 2021 <Link to='/'>Yates Equipement</Link></p>
            </div>
            </Hidden>
            <div className="flexBox social-footer" style={{width:"max-content"}}>
                <div className="facebook"><a target='_blank' href="https://www.facebook.com/yatesequipement/"><img className="lazyload" width="100" height="100" style={{height:"auto", width:"60%"}} data-src={process.env.PUBLIC_URL+"/assets/social/facebook.webp"} alt="facebook yates equipement" /></a></div>
                <div className="linkedin"><a target='_blank' href="https://www.linkedin.com/company/yatesgroup?originalSubdomain=ma"><img className="lazyload" width="100" height="100" style={{height:"auto", width:"60%"}} data-src={process.env.PUBLIC_URL+"/assets/social/linkedin.webp"} alt="linkedin yates equipement" /></a></div>
            </div>
        </div>
    )
}
