import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BackButton } from '../component/BackButton'
import { HeaderArticle } from '../component/HeaderArticle'

export const PageError = () => {
    return (
        <div>
            <HeaderArticle />
            <BackButton />
            <Container className="bloc pageErreur">
                <Row>
                    <Col xs={12} md={7} lg={5}>
                        <h1>Oups !</h1>
                        <h4>La page que vous recherchez semble introuvable.</h4>
                        <span>Code d’erreur : 404</span>
                        <p>Voici quelques liens utiles à la place :</p>
                        <ul>
                            <li><a href="/">Accueil</a></li>
                            <li><a href="/articles/protection des mains">Protection des mains</a></li>
                            <li><a href="/articles/protection des pieds">Protection des pieds</a></li>
                            <li><a href="/articles/protection de la tête">Protection de la tête</a></li>
                            <li><a href="/articles/protection du corps">Protection du corps</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={5} lg={7}>
                        <img style={{width:"100%"}} src={process.env.PUBLIC_URL+"assets/404.svg"} alt="404 Erreur" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
