import React from 'react'
import { Accueil  } from './view/Accueil'
import { Articles  } from './view/Articles'
import { Article  } from './view/Article'
import { PageError } from './view/404'
import { MentionsLegales } from './view/MentionsLegales'
import { Contact } from './view/Contact'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { Footer } from './component/Footer'
import { Navigation } from './component/Navigation'
import { InfoSection } from './component/InfoSection'
import { Copyright } from './component/Copyright'
import db from './firebase.config';
import './App.css';
import { useCookies } from 'react-cookie';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack'


function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}




function App() {

  const [articles, setArticles] = React.useState([])
  const [cookies, setCookie, removeCookie] = useCookies(['newsletter']);
  const [email, setEmail] = React.useState("")
  const { enqueueSnackbar } = useSnackbar()

  window.scrollTo(0, 0)

  
  React.useEffect(() => {
    if (window.location.hostname == "yates-equipement-efb44.web.app" || 
        window.location.hostname == "yates-equipement-efb44.firebaseapp.com") {
          window.location.href = 'https://yates-equipement.com'; 
    }
    let copyData = []
    var docRef = db.collection('navigation').doc("brlrxCURPCv8NyhunPBg")
    docRef.get().then((doc) => {
      if (doc.exists) {
          setArticles(doc.data())
      } else {
          // doc.data() will be undefined in this case
          setArticles({
            corps:["Blouse", "Anti-froid", "Haute visibilité", "Combinaison", "Tablier", "Veste et pantalon"],
            id: "brlrxCURPCv8NyhunPBg",
            mains:["Gant de manutention", "Gant jetable", "Gant anti coupure", "Gant de précision", "Gant anti chaleur", "Gant de maitrise", "Gant de travail", "Manchette"],
            pieds:["S1P SRC", "S5 SRC", "S3 SRC", "Accessoire", "Sabot"],
            tete:["Masque à gaz", "Casque de sécurité", "Masques à gaz", "Protection faciale", "Anti-bruit"]
          })
      }
  }).catch((error) => {
      console.log("Error getting document:", error);
  });
      if(cookies.newsletter !== "false"){
        console.log("coockies existe ")
        setCookie('newsletter', false, { path: '/', expires: new Date(Date.now()+2592000) });
        let d = window.document.getElementById("newsletter-pop-up")
        d.style.display = "block"
        d.style.backgroundImage= `url(${process.env.PUBLIC_URL}/assets/image/Pop_up.png)`
      } else {
        console.log("coockie existe pas")
      }
  }, [])

  const handleCloseNewsletter = () => {
    let d = window.document.getElementById("newsletter-pop-up")
    d.style.display = "none"

  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if(email !== ""){
      db.collection("newsletter").add({
          email:email
      })
      .then((docRef) => {
           enqueueSnackbar("Inscription a la newsletter réussi",{
              variant:"success"
          })
      })
      .catch((error) => {
          enqueueSnackbar("Opération échouée. Veuillez réessayer.",{
              variant:"error"
          })
      });
  } else {
      enqueueSnackbar("Veuillez indiquer votre adresse mail.",{
          variant:"warning"
      })
  }
  }

  return articles ? (
    <Router>
      <ScrollToTop />
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <InfoSection/>
        <Navigation articles={articles} />
        <Switch>
          <Route path="/article/:id">
            <Article />
          </Route>
          <Route path="/articles/:categorie/:type">
            <Articles />
          </Route>
          <Route path="/articles/:categorie">
            <Articles />
          </Route>
          <Route path="/articles">
            <Articles />
          </Route>
          <Route path="/mentions-legales">
            <MentionsLegales />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/">
            <Accueil />
          </Route>
          <Route path="*">
            <PageError />
          </Route>
        </Switch>
        <div style={{position:"static",bottom:"0"}}>
        <Footer />
        <Copyright />
        </div>
        <div id='newsletter-pop-up' className="inscription-newsleter-pop-up">
          <div className="inscription-pop-up inscription">
            <form onSubmit={handleSubmit} id="add-newsletter-cookie">
                <input type="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                <button type="submit">S'INSCRIRE</button>
            </form>
          </div>
          <IconButton className="fermer-newsletter" aria-label="delete" onClick={handleCloseNewsletter}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
    </Router>
  ):null;
}

export default App;
