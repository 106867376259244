import React from 'react'
import { Container } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
export const HeaderArticle = ({categorie}) => {
    return (
        <Container className="headerArticle" fluid={true} style={{padding:"0"}}>
        <div>
        <h2>{categorie}</h2>
        </div>
        <Image src={process.env.PUBLIC_URL+"/assets/header/headerImage.svg"} fluid />
        </Container>
    )
}
