import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { logos } from '../data/Logo_confiance'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { TitleLine} from './title/TitleLine'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Hidden } from '@material-ui/core'

export const Confiance = () => {
    const range = logos.length/4

    return (
        <div className="bloc confiance" style={{marginBottom:"100px"}}>
            <Container>
                <Row style={{textAlign:"center", marginBottom:"25px"}}>
                    <Col>
                        <TitleLine title="Ils nous font confiance" bottom="0px" />
                    </Col>
                </Row>
            </Container>
            <Hidden only={["xs", "sm"]}>
            <Carousel>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/1.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/2.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/3.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/4.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/5.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/6.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/7.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/8.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/9.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/10.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/11.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/12.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/13.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/14.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/15.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/16.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/17.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/18.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/19.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/20.webp"} />
                            </Col>
                        </Row>  
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/21.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/22.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/23.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/24.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                <Container>
                    <Row>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/25.webp"} />
                        </Col>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/26.webp"} />
                        </Col>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/27.webp"} />
                        </Col>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/28.webp"} />
                        </Col>
                    </Row>  
                    <Row>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/29.webp"} />
                        </Col>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/30.webp"} />
                        </Col>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/31.webp"} />
                        </Col>
                        <Col>
                        <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/32.webp"} />
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            </Carousel>  
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
            <Carousel>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/1.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/2.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/3.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/4.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/5.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/6.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/7.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/8.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/9.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/10.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/11.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/12.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/13.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/14.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/15.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/16.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/17.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/18.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/19.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/.20webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/21.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/22.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/23.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/24.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/25.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/26.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/27.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/28.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container>
                        <Row>
                            <Col>
                            <img data-src={process.env.PUBLIC_URL+"/assets/logos/29.webp"} className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/30.webp"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/31.webp"} />
                            </Col>
                            <Col>
                            <img className="w50 center lazyload" width="127" height="76" alt="partenaire yates equipement" data-src={process.env.PUBLIC_URL+"/assets/logos/32.webp"} />
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
            </Carousel>  
            </Hidden>
        
            </div>
        )
}
