import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FooterTitle } from './title/FooterTitle'
import SendIcon from '@material-ui/icons/Send';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import db from '../firebase.config';
import { useSnackbar } from 'notistack'

export const Footer = () => {
    const [newsletter, setNewsletter] = React.useState('')
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = (event) => {
        event.preventDefault()
        if(newsletter !== ""){
            db.collection("newsletter").add({
                email:newsletter
            })
            .then((docRef) => {
                 enqueueSnackbar("Inscription a la newsletter réussi",{
                    variant:"success"
                })
            })
            .catch((error) => {
                enqueueSnackbar("Opération échouée. Veuillez réessayer.",{
                    variant:"error"
                })
            });
        } else {
            enqueueSnackbar("Veuillez indiquer votre adresse mail.",{
                variant:"warning"
            })
        }
    }
    return (
        <footer className="bloc footer" style={{position:"relative", backgroundColor:"#191f23", color:"#fff"}}>
            <div className="w80 footer-contact-info">
            <Container>
                <Row>
                    <Col className="bloc-orange-negative">
                        <Row className="full-height">
                        <div className="image-bloc-orange" style={{position:"absolute", top:"0", width:"100%"}}>
                                <img className="lazyload" width="100" height="100" style={{width:"100%", height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/footer/bloc-orange-left.png"} alt="protection des pieds yates equipement" />
                        </div> 
                            <Col xs={4} md={12} lg={4} style={{padding:"20px"}}>
                                <img width="100" height="50" style={{height:"auto"}}  className="bloc-footer-icon lazyload" data-src={process.env.PUBLIC_URL+"/assets/icon/map.png"} alt="adresse yates equipement" />
                            </Col>
                            <Col xs={8} md={12} lg={8} style={{padding:"15px"}}>
                                <div className="center-vertical">
                                <h5>Adresse</h5>
                                    <p style={{width:"max-content"}}>73 Avenue Ahmed AL Hansali, <br/> Magasin
                                    nᵒ1, Temara</p>
                                </div>    
                            </Col>
                        </Row>
                    </Col>
                    <Col className="bloc-orange-negative">
                        <Row className="full-height">
                        <div className="image-bloc-orange" style={{position:"absolute", top:"0", width:"100%"}}>
                                <img className="lazyload" width="100" height="100" style={{width:"100%", height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/footer/bloc-orange-center.png"} alt="protection des mains yates equipement" />
                        </div> 
                            <Col xs={4} md={12} lg={4} style={{padding:"15px"}}>
                                <img className="bloc-footer-icon lazyload" width="100" height="50" style={{height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/icon/tel.png"} alt="contact yates equipement" />
                            </Col>
                            <Col xs={8} md={12} lg={8} style={{padding:"15px"}}>
                                <div className="center-vertical">
                                    <h5>TELEPHONE</h5>
                                    <p>+212 662 746 731 <br/>+212 537 413 765</p>
                                </div>
                                
                            </Col>
                        </Row>
                    </Col>
                    <Col className="bloc-orange-negative">
                        <Row className="full-height">
                        <div className="image-bloc-orange" style={{position:"absolute", top:"0", width:"100%"}}>
                                <img className="lazyload" width="100" height="100" style={{width:"100%", height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/footer/bloc-orange-right.png"} alt="protection du corps yates equipement" />
                        </div> 
                            <Col xs={4} md={12} lg={4} style={{padding:"15px", paddingRight:"0px"}}>
                                <img className="bloc-footer-icon lazyload" width="100" height="50" style={{height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/icon/email.png"} alt="email yates equipement" />
                            </Col>
                            <Col xs={8} md={12} lg={8} style={{padding:"15px", paddingLeft:"0px"}}>
                                <div className="center-vertical">
                                <h5>EMAIL</h5>
                                <p style={{paddingBottom:"10px", whiteSpace:"nowrap"}}>contact@yates-equipement.com</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>             
                </Row>
            </Container>
            </div>
            <Container fluid={true}>
            <Row className="justify-content-md-center">
            <Col xs={11}>
            <Row className="justify-content-center">
            <Col className="footer-item" xs={12} sm={10} lg={4}>
                <FooterTitle title="A PROPOS DE NOUS" />
                <p>Yates Equipement, spécialiste en équipement
                de protection individuelle au Maroc.  
                Desserve tout le marché marocain depuis 2012.
                </p>
                {/*<a href="#" className="ref-client">Voir nos références clients</a>*/}
            </Col>
            <Col className="footer-item" xs={12} sm={10} lg={4} >
                <FooterTitle title="LIENS UTILES" />
                <div className="flexBox liens-utils">
                <div className="flexBox flexBox-col">
                <a href="/articles/protection des mains">Protection des mains</a>
                <a href="/articles/protection de la tete">Protection de la tête</a>
                <a href="/articles/protection des pieds">Protection des pieds</a>
                <a href="/articles/protection du corps">Protection du corps</a>
                </div>
                <div className="flexBox flexBox-col liens-utils-seconde-col" style={{marginLeft:"20px"}}>
                <a href="/articles/signaletique">Signalétique</a>
                <a href="/mentions-legales">Mention légales</a>
                <a href="/contact">Contacter</a>
                </div>
                </div>
            </Col>
            <Col className="footer-item" xs={12} sm={10} lg={4} >
                <FooterTitle title="NEWSLETTER" />
                <p>Soyez les premiers à être informés des
                nouveautés de Yates Equipement, inscrivez vous
                à notre newsletter. </p>
                <form onSubmit={handleSubmit}>
                    <div className="input-custume">
                    <input type="email" placeholder="Votre adresse email ..." value={newsletter} onChange={(e) => setNewsletter(e.target.value)} />
                    <button type="submit" ><img className="lazyload"  data-src={process.env.PUBLIC_URL+"/assets/icon/send.png"} alt="newsletter yates equipement" /></button>
                    </div>
                </form>
            </Col>
            </Row>
            </Col>
            </Row>
        </Container>
        </footer>
    )
}
