import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export const SliderHeader = () => {
        const [index, setIndex] = React.useState(0);

        const handleSelect = (selectedIndex, e) => {
            setIndex(selectedIndex);
        };
    return (
        <Container className="carousel-Accueil" fluid={true} style={{paddingTop:"15px", position:"relative"}}>
            <Row style={{position:"relative"}}>
                <Col xs={8}>
                <Carousel className="carousel-Accueil" activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <Carousel.Caption style={{position:"absolute", top:"0", opacity:"0"}}>
                        <Container>
                            <h1>masque 3m maroc yates equipement</h1>
                        </Container>
                    </Carousel.Caption>
                        <img src={process.env.PUBLIC_URL+"/assets/header/slider1.webp"} width="100" alt="yates equipement" />
                    </Carousel.Item>
                <Carousel.Item>
                <Carousel.Caption style={{position:"absolute", top:"0", opacity:"0"}}>
                        <Container>
                            <h1>EPI equipement de protection rabat maroc</h1>
                        </Container>
                    </Carousel.Caption>
                <img src={process.env.PUBLIC_URL+"/assets/header/slider2.webp"} width="100" alt="protection de travail maroc" />
                </Carousel.Item>
                <Carousel.Item>
                <Carousel.Caption style={{position:"absolute", top:"0", opacity:"0"}}>
                        <Container>
                            <h1>EPI equipement de protection bouznika maroc</h1>
                        </Container>
                    </Carousel.Caption>
                <img src={process.env.PUBLIC_URL+"/assets/header/slider3.webp"} width="100" alt="epi" />
                </Carousel.Item>
                <Carousel.Item>
                <Carousel.Caption style={{position:"absolute", top:"0", opacity:"0"}}>
                        <Container>
                            <h1>vetement de travail rabat bouznika maroc</h1>
                        </Container>
                    </Carousel.Caption>
                <img src={process.env.PUBLIC_URL+"/assets/header/slider4.webp"} width="100" alt="equipement de protection" />
                </Carousel.Item>
                </Carousel>  
                </Col>
                <Col>
                    <div style={{height:"49%", marginBottom:"10px"}}>
                    <h1 style={{position:"absolute", opacity:"0"}}>Signalétique rabat maroc</h1>
                    <img style={{width:"100%"}} src={process.env.PUBLIC_URL+"/assets/header/1.webp"} width="100" alt="protection individuelle" />
                    </div>
                    <div style={{height:"49%"}}>
                    <h1 style={{position:"absolute", opacity:"0"}}>EPI produits personalisable</h1>
                    <img style={{width:"100%"}} src={process.env.PUBLIC_URL+"/assets/header/2.webp"} width="100" alt="yates equipement maroc"  />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
