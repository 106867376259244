import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { PlayFill } from 'react-bootstrap-icons';
import AnimatedNumber from "animated-number-react";
import TrackVisibility from 'react-on-screen';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export const ChiffreClefs = () => {
    const [makeit, setMakeIt] = React.useState(false)
    
    const formatValue = (value) => parseInt(value);

    const ComponentToTrack = ({ isVisible, number }) => {
        let myhtml = ""
        if(isVisible){
            if(makeit === true){
                myhtml = <span>{number}</span>
            } else {
                myhtml = <span><AnimatedNumber value={number} duration ={1000} formatValue={formatValue}/></span>
                setTimeout(() => {
                    setMakeIt(true)
                }, 1000);
            }
        } else {
            myhtml = <span>{number}</span>
        }
        const style = {
            display: isVisible ? 'inline-bloc !important' : 'none'
        };
        return myhtml;
    }

    return (
        <Container fluid={true} className="big-bloc" style={{backgroundColor:"rgba(0, 0, 0, 0.8)", position:"relative", color:"#fff"}}>
            <div style={{position:"absolute", top:"0", left:"50%", transform:"translateX(-50%) translateY(-60%)", width:"350px"}}>
                <img className="lazyload" style={{width:"100%"}} width="100" height="100" data-src={process.env.PUBLIC_URL+"/assets/image/banierechiffre.png"} alt="yates equipement chiffre clés" />
                <h3 style={{color:"#fff", position:"absolute", top:"50%", left:"50%", transform:"translateX(-50%) translateY(-50%)"}}>Chiffre clés</h3>
            </div>
            <Row className="justify-content-md-center">
                <Col xs={12} lg={11}>
                    <Row className="justify-content-center">
                        <Col xs={8} md={3} lg={3}>
                            <div className="bloc-chiffre felxBox flexBox-col center-text">
                                <div className="filter-chiffre-clef"></div>
                                <div className="info-chiffre-clef" style={{position:"relative"}}>
                                <img className="lazyload" width="100" height="100" data-src={process.env.PUBLIC_URL + "/assets/icon/chiffre1.svg"} alt="produit livré yates equipement" />
                                <div className="center" style={{width:"50%", height:"2px", backgroundColor:"#ff5e14", transition:"1s"}}></div>
                                <p style={{position: "relative",
                                    left: "50%",
                                    transform: "translate(-50%)"
                                }}>
                                    <TrackVisibility>
                                        <ComponentToTrack number={685} />
                                    </TrackVisibility>
                                </p>
                                <p>Clients satisfaits</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={8} md={3} lg={3}>
                            <div className="bloc-chiffre felxBox flexBox-col center-text">
                            <div className="filter-chiffre-clef"></div>
                            <div className="info-chiffre-clef" style={{position:"relative"}}>
                                <img className="lazyload" width="100" height="100" data-src={process.env.PUBLIC_URL + "/assets/icon/chiffre2.svg"} alt="Client yates equipement" />
                                <div className="center" style={{width:"50%", height:"2px", backgroundColor:"#ff5e14", transition:"1s"}}></div>
                                <p style={{position: "relative",
                                left: "50%",
                                transform: "translate(-50%)"
                            }}>
                                <TrackVisibility>
                                        <ComponentToTrack number={12} />
                                    </TrackVisibility>
                                </p>
                                <p>Ans d'expertise</p>
                            </div>
                            </div>
                        </Col>
                        <Col xs={8} md={3} lg={3}>
                            <div className="bloc-chiffre felxBox flexBox-col center-text">
                            <div className="filter-chiffre-clef"></div>
                            <div className="info-chiffre-clef" style={{position:"relative"}}>
                                <img className="lazyload" width="100" height="100" data-src={process.env.PUBLIC_URL + "/assets/icon/chiffre3.svg"} alt="Ville desservi yates equipement" />
                                <div className="center" style={{width:"50%", height:"2px", backgroundColor:"#ff5e14",transition:"1s"}}></div>
                                <p style={{position: "relative",
                                left: "50%",
                                transform: "translate(-50%)"
                            }}>
                                <TrackVisibility>
                                        <ComponentToTrack number={22} />
                                    </TrackVisibility>
                                </p>
                                <p>Marques distribués</p>
                            </div>
                            </div>
                        </Col>
                        <Col xs={8} md={3} lg={3} >
                            <div className="bloc-chiffre felxBox flexBox-col center-text">
                            <div className="filter-chiffre-clef"></div>
                            <div className="info-chiffre-clef" style={{position:"relative"}}>
                                <img className="lazyload" width="100" height="100" data-src={process.env.PUBLIC_URL + "/assets/icon/chifre4.webp"} alt="chiffre yates equipement" />
                                <div className="center" style={{width:"50%", height:"2px", backgroundColor:"#ff5e14", transition:"1s"}}></div>
                                <p style={{position: "relative",
                                left: "50%",
                                transform: "translate(-50%)"
                            }}>
                                <TrackVisibility>
                                    <ComponentToTrack number={75} />
                                    <span>%</span>
                                </TrackVisibility>
                                </p>
                                <p>De la couverture nationale</p>
                            </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
