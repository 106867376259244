import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDeWs0OOUGen9roqADzgJRQ1C8ovjhPtpQ",
  authDomain: "yates-equipement-efb44.firebaseapp.com",
  projectId: "yates-equipement-efb44",
  storageBucket: "yates-equipement-efb44.appspot.com",
  messagingSenderId: "647026587379",
  appId: "1:647026587379:web:718ac21ad152c737a3a986",
  measurementId: "G-R2LSD7D951"
};
  
const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics()
const db = firebase.firestore()

export default db;