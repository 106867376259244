import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { Facebook, Twitter, Linkedin, Youtube } from 'react-bootstrap-icons';

export const InfoSection = () => {
    return (
        <div className="min-bloc" style={{background:"#0B2653", color:"#fff"}}>
        <Container fluid={true}>
            <Row>
                <Col xs={12} lg={8}>
                <div className="flexBox info-top-section">
                <div className="flexBox info-top-section">
                <p style={{whiteSpace:"nowrap"}} className="no-padding">Contactez nous :</p>
                <div className="flexBox">
                <p style={{whiteSpace:"nowrap"}} className="no-padding">+212 537 413 765</p>
                <p style={{whiteSpace:"nowrap"}} className="no-padding">+212 698 991 795</p>
                </div>
                </div>
                <div className="flexBox info-top-section">
                <p style={{whiteSpace:"nowrap"}} className="no-padding">Email :</p>
                <p style={{whiteSpace:"nowrap"}} className="no-padding">contact@yates-equipement.com</p>
                </div>
                
                </div>
                </Col>
                <Col xs={12} lg={4}>
                    <div className="flexBox w100 right none-decoration f-end">
                            <a target='_blank' href="https://www.facebook.com/yatesequipement/"><Facebook className="icon" /></a>
                            <a target='_blank' href="https://www.linkedin.com/company/yatesgroup?originalSubdomain=ma"><Linkedin className="icon" /></a>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
    )
}
