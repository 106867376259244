import React from 'react'

export const TitleLine = ({title, width, bottom}) => {
    const w = width || "20%"
    const b = bottom || "70px"
    return (
        <div style={{textAlign:"center", marginBottom:b}}>
            <h2 style={{color:"#FFD102"}}>{title}</h2>
            <div style={{height:"5px", backgroundColor:"#FFD102", width:w, position:"relative", left:"50%", transform:"translateX(-50%)"}}></div>
        </div>
    )
}
