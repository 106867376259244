import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export const Presentation = () => {
    return (
        <Container fluid={true} className="bloc">
            <Row className="justify-content-md-center">
                <Col xs={12} lg={11}>
                    <Row className="presentation">
                        <Col xs={12} lg={6}>
                        <div className="presentation" style={{width:"90%", position:"relative",left:"50%", transform:"translateX(-50%)"}}>
                            <Row>
                                <Col className="presentation-text">
                                    <h1>Travaillez en sécurité avec Yates Equipement</h1>
                                    <p>La sécurité des collaborateurs est au cœur de tout débat en entreprise. En effet, il est primordial de protéger vos salariés contre les risques susceptibles de menacer leur sécurité lors de l’exercice de leur fonction. 
                                    Opter pour l’Équipement de Protection Individuelle (EPI) adéquat c’est assurer un environnement de travail sûr en éliminant les risques éventuels d’accidents et ainsi augmenter la productivité de vos collaborateurs.
                                    Pour ce faire, choisissez sur notre site l’ensemble des EPI appropriés à votre activité. Tous nos produits répondent aux normes Européenne de sécurité.
                                    Chez Yates Equipement, vous trouverez le nécessaire pour sécuriser les postes de travail.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <div className="flexBox flexBox-col center-text presentation-icon">
                                        <img className="icon-presentation lazyload" data-src={process.env.PUBLIC_URL + "/assets/icon/mission.png"} alt="mission yates equipement" />
                                        <h5>Notre mission</h5>
                                        <p>Répondre à vos besoins en matière d’EPI en proposant les produits les mieux adaptés à votre domaine d’activité.</p>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="flexBox flexBox-col center-text presentation-icon">
                                        <img className="icon-presentation lazyload" data-src={process.env.PUBLIC_URL + "/assets/icon/vision.png"} alt="vision yates equipement" />
                                        <h5>Notre vision</h5>
                                        <p>Devenir un acteur majeur de distribution d’EPI. Couvrir tout le Maroc par lancement de plusieurs points de vente stratégiques.</p>
                                    </div>
                                </Col>
                            </Row>
                            </div> 
                        </Col>
                        <Col xs={12} lg={6} style={{padding:"0px", textAlign:"center"}}>
                        <img className="lazyload" width="100" height="100" style={{width:"90%", height:"auto", position:"relative", top:"0", marginBottom:"25px"}} data-src={process.env.PUBLIC_URL+"/assets/image/presentation-image.webp"} alt="presentation yates equipement" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
