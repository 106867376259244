import React from 'react'
import {Helmet} from "react-helmet";
import JsonLd from './JsonLd';


export const MetaTags = ({meta}) => {

    let data = {
        "@context" : "http://schema.org",
        "@type" : "LocalBusiness",
        "name" : meta.title,
        "image" : meta.image,
        "telephone" : "+212 537 413 765",
        "email" : "contact@yates-equipement.com",
        "address" : {
        "@type" : "PostalAddress",
        "streetAddress" : "73 Avenue Ahmed AL Hansali,",
        "addressRegion" : "Temara",
        "addressCountry" : "Maroc",
        "postalCode" : "10100"
        },
        "openingHours":["Mo-Fr 08:30-19:00", "Sa 08:30-12:30"],
        "url" : meta.url
    }
    return (
        <React.Fragment>
        <Helmet>
            <title>{meta.title}</title>
            <meta property="og:title" content={meta.title} />
            <meta name="description" content={meta.description}/>
            <meta name="og:description" content={meta.description}/>
            <meta property="og:url" content={meta.url} />
            <meta property="og:image" content={meta.image} />
            
            <meta name="og:email" content="contact@yates-equipement.com"/>
            <meta name="og:phone_number" content="0698-991795"/>
            
            <meta name="og:latitude" content="33.913478"/>
            <meta name="og:longitude" content="-6.917841"/>
            
            <meta name="author" content="Marc Yates"/>
            <meta property="og:type" content="website" />
            <meta name="keywords" content={meta.keywords} />
            <link rel="canonical" href={meta.url} />
        </Helmet>
        <JsonLd data={data} />
        </React.Fragment>
    )
}
