import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { TitleLine } from './title/TitleLine'
import { FilterList } from './FilterList'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { FilterLoader } from './loader/FilterLoader'

export const FilterArticles = ({setFiltre, articles, searchWord, typeActive, loader}) => {
    const articles2 = [... articles]
    const [active, setActive] = React.useState(false)
    let type = []
    let type2 = []
    let existe = false

    return !loader?(
        <Col xs={12} lg={3} className="filtet-title" style={{borderRight:"solid #D9D8D8 1px"}}>
            <TitleLine title={articles && articles.length !==0 && searchWord ?articles[0].categorie.charAt(0).toUpperCase() + articles[0].categorie.slice(1):"Equipement de protection individuelle"} width="5%" />
            <div className="sticky" style={{marginBottom:"50px"}}>
            <Accordion className="accordion mobile">
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => {
                        setActive(!active)
                    }}>
                        Filtre 
                        {
                            active?(
                                <svg style={{marginLeft:"15px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up" viewBox="0 0 16 16">
                                <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659l4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"/>
                                </svg>
                            ):(
                                <svg style={{marginLeft:"15px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
                                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                </svg>
                            )
                        }
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse style={{border: "1px solid rgba(0,0,0,.125)"}} eventKey="0">
                    <Card.Body>
                        <ul id="filterId" className="filter">
                        {
                            articles && articles.map(item => {
                                type.map(e => {
                                    if(e === item.type){
                                        existe = true
                                    }
                                })
                                if(!existe){
                                    type.push(item.type)
                                    return <FilterList setFiltre={setFiltre} item={item} typeActive={typeActive} />
                                }
                                existe = false
                            } )
                        }
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
            <div className="desktop">
                <ul id="filterId2" className="filter">
                {
                    articles.map(item => {
                        type2.map(e => {
                            if(e === item.type){
                                existe = true
                            }
                        })
                        if(!existe){
                            type2.push(item.type)
                            return <FilterList setFiltre={setFiltre} item={item} typeActive={typeActive}/>
                        }
                        existe = false
                    } )
                }
                </ul>
            </div>
            
            </div>
        </Col>
    ):<FilterLoader/>
}
