import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Container } from 'react-bootstrap'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export const Navigation = ({articles}) => {

    return (
        <Navbar className="navigation" sticky="top" collapseOnSelect expand="lg" variant="light" style={{height:"50px",paddingBottom:"0", paddingTop:"0", background:"#FFD102", paddingLeft:"0"}}>
        <div className="square">
            <Nav.Link className="logo" href="/" style={{position:"relative", height:"100%", padding:"0"}}>
            <img className="lazyload" data-src={process.env.PUBLIC_URL +'/assets/logo/squareLogo.svg'} width="100" height="100" style={{position:"relative", height:"130%", width:"auto", marginTop:"-12px"}} alt="yates equipement" />
            </Nav.Link>
        </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
            <Container className="container-navigation">
                <Nav className="flexBox f-between w80" style={{right:"0"}}>
                <Nav.Link href="/">Accueil</Nav.Link>
                <NavDropdown title="Mains" id="collasible-nav-dropdown">
                <NavDropdown.Item href={`/articles/protection des mains`}><h6 style={{textDecoration:"underline", fontWeight:"bold"}}>Protections des mains</h6></NavDropdown.Item>
                {
                    articles.mains?.map((type) => (
                        <NavDropdown.Item href={`/articles/protection des mains/${type}`}>{type}</NavDropdown.Item>
                    ))
                }
                </NavDropdown>
                <NavDropdown title="Pieds" id="collasible-nav-dropdown">
                <NavDropdown.Item href={`/articles/protection des pieds`}><h6 style={{textDecoration:"underline", fontWeight:"bold"}}>Protections des pieds</h6></NavDropdown.Item>

                {
                    articles.pieds?.map((type) => (
                        <NavDropdown.Item href={`/articles/protection des pieds/${type}`}>{type}</NavDropdown.Item>
                    ))
                }
                </NavDropdown>
                <NavDropdown title="Tête" id="collasible-nav-dropdown">
                <NavDropdown.Item href={`/articles/protection de la tête`}><h6 style={{textDecoration:"underline", fontWeight:"bold"}}>Protections de la tête</h6></NavDropdown.Item>
                {
                    articles.tete?.map((type) => (
                        <NavDropdown.Item href={`/articles/protection de la tête/${type}`}>{type}</NavDropdown.Item>
                    ))
                }
                </NavDropdown>
                <NavDropdown title="Corps" id="collasible-nav-dropdown">
                <NavDropdown.Item href={`/articles/protection du corps`}><h6 style={{textDecoration:"underline", fontWeight:"bold"}}>Protections du corps</h6></NavDropdown.Item>
                {
                    articles.corps?.map((type) => (
                        <NavDropdown.Item href={`/articles/protection du corps/${type}`}>{type}</NavDropdown.Item>
                    ))
                }
                </NavDropdown>
             <NavDropdown title="Signalétique" id="collasible-nav-dropdown">
                <NavDropdown.Item href={`/articles/signaletique`}><h6 style={{textDecoration:"underline", fontWeight:"bold"}}>Signaletique</h6></NavDropdown.Item>
                {
                    articles.signaletique?.map((type) => (
                        <NavDropdown.Item href={`/articles/signaletique/${type}`}>{type}</NavDropdown.Item>
                    ))
                }
            </NavDropdown>
                
                <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
            </Container>
            </Navbar.Collapse>
        </Navbar>
    )
}
